import * as Sentry from '@sentry/vue';
import CommonHeader from '../../components/CommonHeader/CommonHeader.vue';
import Information from '../../HomePage/components/Information.vue';
import Copyright from '../../components/Copyright/Copyright.vue';
import { RequestFactory } from '@Request/RequestFactory';
import { designIcons } from '@/utils/mediaData';
import { isMobile } from '@/utils/commonUtils';
import {
  BContainer,
  BRow,
  BCol,
  BIcon,
  BForm,
  BFormSelect,
  BPagination,
  BCarousel,
  BCarouselSlide,
  BOverlay,
  BButton
} from 'bootstrap-vue';

const defaultPerPage = 12;
const defaultPage = 1;
const CustomerRequest = RequestFactory.get('customers');

export default {
  name: 'CustomerDesignPage',
  components: {
    CommonHeader,
    Information,
    Copyright,
    BContainer,
    BRow,
    BCol,
    BIcon,
    BForm,
    BFormSelect,
    BPagination,
    BCarousel,
    BCarouselSlide,
    BOverlay,
    BButton
  },
  data() {
    return {
      designs: [],
      paginationModel: {},
      logo: designIcons.logo,
      isMobile,
      showConfirm: false,
      objectConfirm: {}
    };
  },
  created() {
    this.fetchAllData();
  },
  watch: {
    $route(to, from) {
      this.fetchAllData();
    }
  },
  methods: {
    async fetchProductList(perPage, page) {
      try {
        const {
          data: { response }
        } = await CustomerRequest.getCustomerDesigns();
        this.designs = response.data;

        this.paginationModel = {
          currentPage: response.current_page,
          lastPage: response.last_page,
          perPage: response.per_page,
          total: response.total
        };
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async fetchAllData() {
      const loader = this.$loading.show();

      if (this.$store.state.user.data.token) {
        await this.fetchProductList(defaultPerPage, defaultPage);

        this.$nextTick(function() {
          loader.hide();
        });
      } else {
        await this.$router.push({ name: 'home' });
      }
    },
    async fetchProductPagination(page) {
      const loader = this.$loading.show();
      await this.fetchProductList(this.paginationModel.perPage, page);

      this.$nextTick(function() {
        loader.hide();
        this.$scrollTo('#list-designs', 1000);
      });
    },
    async onDeleteDesign(templateId) {
      const _this = this;
      const loader = _this.$loading.show();

      if (_this.$store.state.user.data.token) {
        try {
          CustomerRequest.deleteCustomerDesign(templateId)
            .then(function({ data }) {
              const { message } = data;
              _this.$toastr.s(message);
              _this.fetchAllData();
              loader.hide();
            })
            .catch(function({ response }) {
              const { data } = response;
              const { message } = data;
              loader.hide();
              _this.$toastr.e(message);
            });
        } catch (error) {
          loader.hide();
          Sentry.captureException(error);
        } finally {
          this.showConfirm = false;
        }
      } else {
        this.$router.push({ name: 'home' });
      }
    },
    onSubmit(templateId) {
      this.objectConfirm[templateId] = true;
      this.showConfirm = true;
    },
    onShown(templateId) {
      // Focus the dialog prompt
      this.$refs['dialog-' + templateId].focus();
    },
    onHidden(templateId) {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs['submit-' + templateId].focus();
    },
    onCancel(templateId) {
      this.objectConfirm[templateId] = false;
      this.showConfirm = false;
    }
  }
};
